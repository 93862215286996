import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "file-uploader",
      "style": {
        "position": "relative"
      }
    }}>{`File Uploader`}</h1>
    <p>{`Permite ao usuário transferir e submeter conteúdo do próprio computador. A seleção do arquivo pode ser feita clicando no componente de `}<em parentName="p">{`input`}</em>{` e selecionando o arquivo através da janela nativa ou utilizando `}<em parentName="p">{`drag and drop`}</em>{` (arrastar e soltar).`}</p>
    <p>{`O componente indica ao usuário o progresso do `}<em parentName="p">{`upload`}</em>{` e fornece feedbacks sobre o status do envio.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Sempre mantenha o nome do arquivo visível.`}</li>
      <li parentName="ul">{`Forneça informações sobre tamanho e tipo do arquivo, bem como velocidade de upload/download ou progresso quando possível.`}</li>
    </ul>
    <h2 {...{
      "id": "tipos-de-status",
      "style": {
        "position": "relative"
      }
    }}>{`Tipos de status`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Carregando</th>
      <td>
        Informa ao usuário o progresso do upload. Provê informações como: tamanho do arquivo e estimativa de velocidade
        do upload.
      </td>
    </tr>
    <tr>
      <th>Erro de upload</th>
      <td>Qualquer erro de upload deve vir acompanhado da mensagem de erro.</td>
    </tr>
    <tr>
      <th>Upload completo</th>
      <td>
        Quando completo, será apresentado o thumbnail do arquivo e o ícone. Uma mensagem de sucesso indicara a situação
        do upload.
      </td>
    </tr>
    <tr>
      <th>Pausado</th>
      <td>Apenas para arquivos grandes, possibilita ao usuário a pausar e continuar carregando o arquivo.</td>
    </tr>
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      